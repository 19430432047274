import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import _ from 'underscore';

import { Card } from 'components/Card/Card';
import InitialInfoCard from 'components/Card/InitialInfoCard';
import Select from 'react-select';
import Button from 'components/CustomButton';
import { onlyUnique, cloneDeep, scrollTo, campusLogos } from 'components/modules/_misc';
import { create_proactive } from 'components/modules/ada';
import { DEFAULT } from 'components/modules/app';
import { CAMPUS_CODES } from 'components/modules/campus';
import {
	IS,
	ACAD_LEVELS,
	GET_LABEL,
	MATCHES_SEARCH,
	MODIFIER_GROUPS,
	filter_programs_by_app_creator
} from 'components/modules/programs';
import { find_a_program as styles } from 'components/modules/styles';
import _svg from 'components/modules/svg';
import { UserActions } from 'reducers/user';

const getDistanceLearningFlag = prog => {
	if (prog.field_flags?.includes('Online 100%')) return 'Online';
	if (prog.field_flags?.includes('Blended')) return 'Online/In-Person';

	return 'In-Person';
};

class SelectMajor extends Component {
	constructor(props) {
		super(props);

		const { app } = props,
			campus_code =
				app.application_modifier === 'DA'
					? Object.keys(app.initial_information.direct_admit)[0]
					: app.initial_information.chosen_campus.field_abbreviation;

		this.acad_level = MODIFIER_GROUPS.GRAD.includes(app.application_modifier) ? 'Graduate' : 'Undergraduate';
		this.only_allow_online =
			app.initial_information.alien_status === 'Intl Online' && !app.application_modifier.includes('_S');

		const objState = this.initializePageState(campus_code, true);
		objState.campus_code = campus_code;

		this.state = objState;
	}

	componentDidMount() {
		this._mounted = true;

		setTimeout(() => {
			if (this._mounted) {
				create_proactive(
					'Looking for more information about majors, how to select a major, or curious about selecting undecided?',
					'5ebae6a483b16dea62624ceb'
				);
			}
		}, 2000);
	}

	componentDidUpdate(prevProps, prevState) {
		const { app, updateApp } = this.props,
			{ campus_code, area_of_study, _string, _career_clusters, _filters, backup_program } = this.state,
			search_changed =
				_string !== prevState._string ||
				_career_clusters !== prevState._career_clusters ||
				_filters.join('*') !== prevState._filters.join('*');

		if (search_changed) this.onSearch();

		if (campus_code !== prevState.campus_code) this.setState(this.initializePageState(campus_code, false));

		if (!_.isEqual(area_of_study, prevState.area_of_study)) {
			if (app.application_modifier === 'DA') {
				let initial_information = cloneDeep(app.initial_information);
				initial_information.direct_admit[campus_code] = area_of_study;

				updateApp({ initial_information: initial_information });
			} else {
				updateApp({ area_of_study: area_of_study });
			}
		}

		if (backup_program && !prevState.backup_program) {
			this.setState({
				_string: '',
				_career_clusters: [],
				_filters: [],
				results: [],
				modal: false
			});

			setTimeout(() => {
				scrollTo(0);
			}, 50);
		}
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	initializePageState = (campus_code, onMount) => {
		let { app, campuses, career_clusters, prev_page, cdrMajors, specials } = this.props,
			campus = campuses.find(c => c.field_abbreviation === campus_code),
			majors = filter_programs_by_app_creator(app, cdrMajors, specials, campus),
			area_of_study =
				app.application_modifier === 'DA'
					? app.initial_information.direct_admit[campus_code]
					: app.area_of_study;

		this.orig_program = cloneDeep(app.area_of_study.selected_primary_1 || {});

		if (!onMount) {
			setTimeout(() => {
				scrollTo(0);
			}, 50);
		}

		return {
			_string: '',
			_career_clusters: [],
			_filters: [],
			area_of_study: cloneDeep(area_of_study),
			majors: majors,
			results: majors,
			modal: false,
			flex_modal:
				onMount &&
				prev_page === '/select-campus' &&
				campus.title?.includes('Flex') &&
				app.application_modifier?.includes('_S'),
			backup_program: false,
			opts: {
				career_clusters: career_clusters.map(str => ({ label: str, value: str })),
				degree_type: ACAD_LEVELS.base[this.acad_level]
					.map(code => ACAD_LEVELS.names[code])
					.filter(onlyUnique)
					.map(str => ({ label: str, value: `DEGREE-TYPE|${str}` })),
				program_type: this.setOptsProgramType(majors),
				delivery: [
					{ label: 'In-Person', value: 'DELIVERY|In-Person' },
					{ label: 'Online', value: 'DELIVERY|Online' }
				]
			}
		};
	};

	onUpdate = area_of_study => {
		let { selected_primary_1, selected_primary_2 } = area_of_study;

		if (!!selected_primary_1.id && selected_primary_1.id === selected_primary_2.id) {
			area_of_study.selected_primary_2 = {};
			area_of_study.selected_primary_2_delivery_method = '';
		}

		this.setState({ area_of_study: area_of_study });
	};

	setOptsProgramType = majors => {
		let arr = [],
			majors_at_current_acad_level = majors.filter(major =>
				ACAD_LEVELS.base[this.acad_level].includes(major.field_academic_level)
			);

		majors_at_current_acad_level.forEach(major => {
			const { field_cmc_program_type: _type } = major;
			if (_type && !arr.includes(_type)) arr.push(_type);

			major.sub_programs.forEach(sub_program => {
				const { field_cmc_program_type: _type } = sub_program;
				if (_type && !arr.includes(_type)) arr.push(_type);
			});
		});

		arr.sort((x, y) => {
			if (x < y) return -1;
			if (x > y) return 1;
			return 0;
		});

		return arr.map(str => ({ label: str, value: `PROG-TYPE|${str}` }));
	};

	onSearch = () => {
		const { _string, _career_clusters, _filters, majors } = this.state,
			_matchProgram = prog =>
				MATCHES_SEARCH._string(prog, _string) &&
				MATCHES_SEARCH._career_cluster(prog, _career_clusters) &&
				MATCHES_SEARCH._degree_type(prog, _filters) &&
				MATCHES_SEARCH._delivery(prog, _filters) &&
				MATCHES_SEARCH._program_type(prog, _filters),
			results = majors.filter(
				rec => _matchProgram(rec) || rec.sub_programs?.some(subProg => _matchProgram(subProg))
			);

		// the secondary sort is campus, which is already done within mapStateToProps
		results.sort((x, y) => {
			if (x.title < y.title) return -1;
			if (x.title > y.title) return 1;
			return 0;
		});

		this.setState({ results: results });
	};

	onSelect = (prog, sub_prog) => {
		let { app } = this.props,
			{ backup_program } = this.state,
			area_of_study = cloneDeep(this.state.area_of_study),
			selected_prog = sub_prog || prog,
			deselected = false;

		if (!app.date_submitted) {
			if (backup_program) {
				deselected = selected_prog.id === area_of_study.selected_primary_2.id;

				area_of_study.selected_primary_2 = deselected ? DEFAULT.PROGRAM : selected_prog;
			} else {
				deselected = selected_prog.id === area_of_study.selected_primary_1.id;

				area_of_study.selected_primary_1_delivery_method = '';
				area_of_study.selected_primary_1 = deselected ? DEFAULT.PROGRAM : selected_prog;

				area_of_study.selected_primary_2 = {};
			}

			area_of_study.selected_primary_2_delivery_method = '';

			this.onUpdate(area_of_study);

			if (!deselected) {
				setTimeout(() => {
					scrollTo(document.body.scrollHeight);
				}, 50);
			}
		}
	};

	onSelectUndecided = () => {
		let { backup_program } = this.state,
			area_of_study = cloneDeep(this.state.area_of_study),
			selected_field = `selected_primary_${backup_program ? '2' : '1'}`;

		area_of_study[selected_field] = {
			...DEFAULT.PROGRAM,
			title: 'Undecided',
			field_cmc_program_type: 'None'
		};

		if (!backup_program) {
			area_of_study.selected_primary_2 = {};
			area_of_study.selected_primary_1_delivery_method = '';
		}

		area_of_study.selected_primary_2_delivery_method = '';

		this.onUpdate(area_of_study);

		setTimeout(() => {
			scrollTo(document.body.scrollHeight);
		}, 50);
	};

	onDelete = () => {
		let { backup_program } = this.state,
			area_of_study = cloneDeep(this.state.area_of_study);

		if (backup_program) {
			area_of_study.selected_primary_2 = {};
			area_of_study.selected_primary_2_delivery_method = '';
		} else {
			area_of_study.selected_primary_1 = {};
			area_of_study.selected_primary_1_delivery_method = '';
		}

		this.onUpdate(area_of_study);
	};

	onBack = () => {
		const { app, bypass_app_type, navigate } = this.props,
			{ campus_code } = this.state,
			{ direct_admit } = app.initial_information;

		if (app.application_modifier === 'DA') {
			const i = Object.keys(direct_admit).indexOf(campus_code);

			if (i) {
				this.setState({ campus_code: Object.keys(direct_admit)[i - 1] });
			} else {
				navigate('/direct-admit/select-campus');
			}
		} else {
			navigate(bypass_app_type ? '/select-modifier' : '/select-campus');
		}
	};

	onExitCreateAppProcess = () => {
		let { bypass_app_type, removeBypassObject, navigate } = this.props;

		if (bypass_app_type) {
			confirmAlert({
				title: 'Drop Term Bypass?',
				message: 'This will forgo the term bypass link you were provided.  Continue?',
				buttons: [
					{
						label: 'Yes',
						onClick: () => {
							removeBypassObject();
							navigate('/my-account');
						}
					},
					{
						label: 'No'
					}
				]
			});
		} else {
			navigate('/my-account');
		}
	};

	onSubmit = () => {
		let { backup_program, area_of_study } = this.state,
			{
				selected_primary_1,
				selected_primary_1_delivery_method,
				selected_primary_2,
				selected_primary_2_delivery_method
			} = area_of_study,
			blended_program =
				(!selected_primary_1_delivery_method && selected_primary_1.field_flags?.includes('Blended')) ||
				(!selected_primary_2_delivery_method && selected_primary_2.field_flags?.includes('Blended'));

		if (blended_program && !this.only_allow_online) {
			this.setState({ modal: true });
		} else if (!backup_program && selected_primary_1.field_selective === '1') {
			this.setState({ backup_program: true });
		} else {
			this.finishMajorSelection();
		}
	};

	onSelectDeliveryMethod = obj => {
		const { app } = this.props,
			area_of_study = cloneDeep(this.state.area_of_study);

		if (!app.date_submitted) {
			area_of_study[obj.stateVariable] = obj.value;
			this.onUpdate(area_of_study);
		}

		this.setState({ modalShow: false });
		this.finishMajorSelection();
	};

	finishMajorSelection = () => {
		let { app, navigate, setScreensCompleted, updateApp, bypass_term, terms, creating_additional_app } = this.props,
			{ campus_code } = this.state,
			arg = { selectAreaStudyCompleted: true },
			initial_information = cloneDeep(app.initial_information),
			{ direct_admit } = initial_information,
			area_of_study = cloneDeep(this.state.area_of_study);

		// if program changes, force re-completion of term selection
		if (app.application_modifier !== 'DA' && this.orig_program.id !== area_of_study.selected_primary_1.id) {
			if (!creating_additional_app) initial_information.chosen_semester = { id: '', title: '', global_term: '' };

			if (bypass_term) {
				let my_terms = this.acad_level === 'Graduate' ? terms.grad : terms.default_undergrad;

				if (MODIFIER_GROUPS.SPECIAL.includes(app.application_modifier)) my_terms = terms.special;

				const term = my_terms.find(t => t.field_global_term === bypass_term.id);
				initial_information.chosen_semester = {
					id: term.id,
					title: term.title,
					global_term: term.field_global_term
				};
			}

			arg.selectSemesterCompleted = false;
			arg.summaryCompleted = false;
		}

		if (this.only_allow_online) {
			['1', '2'].forEach(i => {
				if (
					!area_of_study[`selected_primary_${i}_delivery_method`] &&
					area_of_study[`selected_primary_${i}`].field_flags?.includes('Blended')
				) {
					area_of_study[`selected_primary_${i}_delivery_method`] = 'Online';
					this.onUpdate(area_of_study);
				}
			});
		}

		updateApp({ initial_information: initial_information });

		if (app.application_modifier === 'DA') {
			const i = Object.keys(direct_admit).indexOf(campus_code);

			if (i === Object.keys(direct_admit).length - 1) {
				setScreensCompleted(arg);
				navigate('/summary');
			} else {
				this.setState({ campus_code: Object.keys(direct_admit)[i + 1] });
			}
		} else {
			setScreensCompleted(arg);
			navigate(bypass_term ? '/summary' : '/select-semester');
		}
	};

	highlightMatch = _value => {
		let { _string } = this.state,
			value = `${_value}`;

		if (_string) {
			const i = value.toLowerCase().indexOf(_string.toLowerCase());

			if (i > -1) {
				const str_1 = value.substr(0, i),
					str_2 = value.substr(i, _string.length),
					str_3 = value.substr(i + _string.length);

				value = (
					<>
						{str_1}
						<span className='str-match'>{str_2}</span>
						{str_3}
					</>
				);
			}
		}

		return <>{value}</>;
	};

	renderMajor = prog => {
		let { app } = this.props,
			{ _string, backup_program, area_of_study } = this.state,
			prog_acad_level = ACAD_LEVELS.names[prog.field_academic_level],
			is_special = MODIFIER_GROUPS.SPECIAL.includes(app.application_modifier),
			simple_distance_learning = !prog.sub_programs?.some(
				subProg => getDistanceLearningFlag(subProg) !== getDistanceLearningFlag(prog)
			),
			keywords = prog.field_keywords || [],
			matching_keywords = [],
			matches_text_search = value => !!_string.length && value.toLowerCase().indexOf(_string.toLowerCase()) > -1,
			selected_id = area_of_study[`selected_primary_${backup_program ? '2' : '1'}`].id,
			other_program_id = area_of_study[`selected_primary_${backup_program ? '1' : '2'}`].id,
			is_disabled = _prog =>
				_prog.id === other_program_id || (this.only_allow_online && !IS.online(_prog, false)),
			show_matching_keywords =
				!!_string.length &&
				!matches_text_search(prog.title) &&
				!prog.sub_programs?.some(sub_prog => matches_text_search(sub_prog.title));

		(prog.sub_programs || []).forEach(subProg => {
			(subProg.field_keywords || []).forEach(keyword => {
				if (!keywords.includes(keyword)) keywords.push(keyword);
			});
		});

		if (show_matching_keywords) {
			matching_keywords = keywords
				.sort()
				.map(keyword => {
					return matches_text_search(keyword) ? this.highlightMatch(keyword) : null;
				})
				.filter(str => !!str);
		}

		return (
			<section key={prog.id} className='find-prog'>
				<h3>{!!prog_acad_level && <span>{prog_acad_level}</span>}</h3>

				<a
					data-value={prog.id}
					className={`parent-line ${prog.id === selected_id ? 'selected' : ''}${
						is_disabled(prog) ? ' disabled' : ''
					}`}
					onClick={() => {
						if (!is_disabled(prog)) this.onSelect(prog);
					}}>
					<span>
						{this.highlightMatch(prog.title)}

						{!!prog.field_help_text && (
							<>
								<a id={`tooltip|${prog.id}`} className='prog-tooltip'>
									<img
										className={'informationTooltip'}
										src={require('assets/img/Information-link.png')}
										alt='Information'
									/>
								</a>

								<Tooltip
									anchorId={`tooltip|${prog.id}`}
									className='tooltipContainer'
									delayHide={1000}
									effect='solid'
									content={<p>{prog.field_help_text}</p>}
								/>
							</>
						)}

						{(prog.field_cmc_program_type || 'Major') !== 'Major' && (
							<span className='prog-type'>| {prog.field_cmc_program_type}</span>
						)}
					</span>

					{!is_special && <span className='dist-learning'>{getDistanceLearningFlag(prog)}</span>}
				</a>

				{prog.sub_programs?.map(sub_program => (
					<a
						data-value={sub_program.id}
						role='button'
						onClick={() => {
							if (!is_disabled(sub_program)) this.onSelect(prog, sub_program);
						}}
						className={`child-line ${sub_program.id === selected_id ? 'selected' : ''}${
							is_disabled(sub_program) ? ' disabled' : ''
						}`}
						key={`${prog.id}|${sub_program.id}`}>
						<span className={simple_distance_learning ? '' : 'cut-off'}>
							{this.highlightMatch(sub_program.title)}

							{!!sub_program.field_help_text && (
								<>
									<a id={`tooltip|${sub_program.id}`} className='prog-tooltip'>
										<img
											className={'informationTooltip'}
											src={require('assets/img/Information-link.png')}
											alt='Information'
										/>
									</a>

									<Tooltip
										anchorId={`tooltip|${sub_program.id}`}
										className='tooltipContainer'
										delayHide={1000}
										effect='solid'
										content={<p>{sub_program.field_help_text}</p>}
									/>
								</>
							)}

							{(sub_program.field_cmc_program_type || 'Concentration') !== 'Concentration' && (
								<span className='prog-type'>| {sub_program.field_cmc_program_type}</span>
							)}
						</span>

						{!simple_distance_learning && !is_special && (
							<span className='dist-learning'>{getDistanceLearningFlag(sub_program)}</span>
						)}
					</a>
				))}

				{!!matching_keywords.length && (
					<p className='keywords'>
						matched keywords:{' '}
						{matching_keywords.map((obj, i) => (
							<React.Fragment key={i}>
								{!!i && ' | '}
								{obj}
							</React.Fragment>
						))}
					</p>
				)}
			</section>
		);
	};

	renderModal() {
		let { modal, area_of_study } = this.state,
			_isBlended = i =>
				area_of_study[`selected_primary_${i}`].field_flags?.includes('Blended') &&
				area_of_study[`selected_primary_${i}_delivery_method`] === '',
			key = _isBlended(1) ? 1 : -1;

		if (key < 0 && _isBlended(2)) key = 2;

		return (
			<Modal show={modal}>
				<Modal.Header>
					<Modal.Title className='h2'>Major Delivery Method</Modal.Title>
				</Modal.Header>
				<Modal.Body className='modalMinHeight'>
					<form className='text-center'>
						{key > 0 && (
							<>
								<p>
									{area_of_study[`selected_primary_${key}`].title} is offered in person and online.
									Please specify which delivery method you would like.
								</p>

								<InitialInfoCard
									cards={[
										{
											display: 'In Person',
											image: require('assets/img/InPersonMajorDelivery.png'),
											value: 'In Person'
										},
										{
											display: 'Online',
											image: require('assets/img/OnlineMajorDelivery.png'),
											value: 'Online'
										}
									]}
									stateVariable={`selected_primary_${key}_delivery_method`}
									onChange={this.onSelectDeliveryMethod}
								/>
							</>
						)}
					</form>
				</Modal.Body>
				<Modal.Footer>
					<Button bsStyle='info' className='save-btn' fill onClick={() => this.setState({ modal: false })}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	renderFlexModal = () => {
		let { flex_modal } = this.state;

		return (
			<Modal show={flex_modal}>
				<Modal.Header>
					<Modal.Title className='h2'>UW Flexible Option Courses</Modal.Title>
				</Modal.Header>
				<Modal.Body className='modalMinHeight'>
					<p>
						The UW Flexible Option offers a select number of courses in an online, competency-based format.
						Before applying, please confirm that the course(s) you want to take is offered in this format.
					</p>
					<p>
						For help, see{' '}
						<a
							target='_blank'
							rel='noopener noreferrer'
							href='https://flex.wisconsin.edu/online-course-special-students/'>
							the UW Flexible Option website
						</a>{' '}
						or contact an enrollment advisor at 608-800-6762 or{' '}
						<a href='mailto:flex@uwex.wisconsin.edu'>flex@uwex.wisconsin.edu</a>.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						bsStyle='info'
						className='save-btn'
						fill
						onClick={() => this.setState({ flex_modal: false })}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	renderUndecidedBlock = () => {
		const { app } = this.props,
			{ campus_code } = this.state;

		if (
			!CAMPUS_CODES[campus_code] ||
			MODIFIER_GROUPS.GRAD.includes(app.application_modifier) ||
			MODIFIER_GROUPS.SPECIAL.includes(app.application_modifier)
		) {
			return null;
		}

		return (
			<p className='select-undecided'>
				or, select{' '}
				<Button className='link-button-red' onClick={() => this.onSelectUndecided()}>
					Undecided
				</Button>
			</p>
		);
	};

	renderSelectedBlock = () => {
		const { app, campuses } = this.props,
			{ majors, backup_program, area_of_study, campus_code } = this.state,
			{ selected_primary_1, selected_primary_2 } = area_of_study,
			campus = campuses.find(c => c.field_abbreviation === campus_code),
			prog_to_show = backup_program ? selected_primary_2 : selected_primary_1,
			parent_prog = majors.find(prog => prog.sub_programs?.some(subProg => subProg.id === prog_to_show.id)),
			header =
				app.application_modifier === 'DA'
					? `SELECTED MAJOR/PROGRAM FOR ${campus.title.toUpperCase()}`
					: `SELECTED ${app.application_modifier.includes('_S') ? 'PROGRAM' : 'MAJOR/PROGRAM'}:`;

		if (!prog_to_show?.title) return null;

		return (
			<div className='programSelected'>
				<h2>{header}</h2>

				<div className='majorSelectedContainer'>
					<div>
						{parent_prog ? (
							<>
								<h3>{parent_prog.title}</h3>
								<h4>{GET_LABEL.selectable(prog_to_show, app)}</h4>
							</>
						) : (
							<h3>{GET_LABEL.selectable(prog_to_show, app)}</h3>
						)}
					</div>
					<div>
						<Button bsStyle='info' onClick={e => this.onDelete()} className='deleteBtn btn-xs'>
							<img
								alt='deleteIcon'
								className='imgDashboard'
								height='18px'
								width='18px'
								src={_svg.Delete}
							/>
							<p className='pDashboard'>Delete</p>
						</Button>
					</div>
				</div>
			</div>
		);
	};

	renderSearchForm = () => {
		let { results, _string, _career_clusters, _filters, opts } = this.state,
			filter_groups = [
				{
					label: 'Degree Type',
					options: opts.degree_type
				},
				{
					label: 'Course Delivery',
					options: opts.delivery
				},
				{ label: 'Program Type', options: opts.program_type }
			];

		if (this.only_allow_online) filter_groups.splice(1, 1);

		return (
			<>
				<div className='major-search-controls'>
					<input
						type='text'
						name='majorValue'
						placeholder='What do you want to study...?'
						aria-label='Search for a major'
						value={_string}
						onChange={e => this.setState({ _string: e.target.value })}
					/>
					{!!_string && (
						<button
							className='major-clear'
							aria-label='Clear major search'
							onClick={e => this.setState({ _string: '' })}>
							&times;
						</button>
					)}

					<Select
						isMulti={true}
						placeholder='Select a Career Cluster...'
						name='careerCluster'
						aria-label='Select a Career Cluster'
						options={opts.career_clusters}
						classNamePrefix='select'
						onChange={arr => this.setState({ _career_clusters: arr || [] })}
						value={_career_clusters}
						styles={styles.control_styles.career_cluster}
						components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
					/>
					<Select
						isMulti={true}
						placeholder='Filter'
						name='filter'
						aria-label='Filter'
						options={filter_groups}
						classNamePrefix='select'
						onChange={arr => this.setState({ _filters: arr || [] })}
						value={_filters}
						styles={styles.control_styles.filters}
						components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
					/>
				</div>

				{results.length ? (
					<>
						<div className='select-major-results-line'>
							<p className='major-results-counter'>Total Results: {results.length}</p>
							{this.renderUndecidedBlock()}
						</div>

						{results.map(prog => this.renderMajor(prog))}
					</>
				) : (
					<>
						{this.renderUndecidedBlock()}

						{(!!_string.length || !!_career_clusters.length || !!_filters.length) && (
							<p className='no-progs'>Sorry, no available programs meet those criteria</p>
						)}
					</>
				)}
			</>
		);
	};

	renderShortList = () => {
		const { majors } = this.state;

		return (
			<>
				<div className='short-list-line'>
					<h2>What do you want to study...?</h2>

					{this.renderUndecidedBlock()}
				</div>

				{majors.map(prog => this.renderMajor(prog))}
			</>
		);
	};

	render = () => {
		let { app, campuses, bypass_app_type } = this.props,
			{ backup_program, majors, area_of_study, campus_code } = this.state,
			campus = campuses.find(c => c.field_abbreviation === campus_code),
			{ selected_primary_1, selected_primary_2 } = area_of_study,
			selection_missing = !(backup_program ? selected_primary_2 : selected_primary_1)?.title;

		return (
			<Card
				title='CHOOSE A MAJOR / PROGRAM'
				content={
					<div className='select-major-card'>
						{majors.length ? (
							<>
								{this.renderModal()}
								{this.renderFlexModal()}

								<p className='intro-copy extra'>
									{backup_program ? (
										<>
											You have chosen a selective Major/Program. Please select a backup
											Major/Program.
										</>
									) : (
										<>
											Please select <strong>one</strong> major/program that you are interested in
											studying {app.application_modifier === 'DA' ? `at ${campus.title}` : ''}{' '}
											using the search below.
											<a id='tooltip|major' className='prog-tooltip'>
												<img
													className={'informationTooltip'}
													src={require('assets/img/Information-link.png')}
													alt='Information'
												/>
											</a>
										</>
									)}
								</p>
								<Tooltip
									anchorId='tooltip|major'
									className='tooltipContainer'
									delayHide={1000}
									effect='solid'
									content={
										<p>
											Selecting a major/program that you intend to study does not guarantee
											admission into that major/program, nor does it require you to pursue that
											major/program. If you are unsure about what major/program to select, choose
											Undecided from the list below, if applicable.
										</p>
									}
								/>

								<img
									src={campusLogos[campus.field_abbreviation]}
									className='img-major'
									alt={campus.title + ' campus logo'}
								/>

								{majors.length <= 5 || MODIFIER_GROUPS.SPECIAL.includes(app.application_modifier)
									? this.renderShortList()
									: this.renderSearchForm()}

								{this.renderSelectedBlock()}
							</>
						) : (
							<p className='intro-copy' data-value={app.application_modifier}>
								Hmm, it looks like we don&apos;t have any programs available for that application type.
							</p>
						)}
					</div>
				}
				buttons={
					<>
						<Button bsStyle='info' className='back-btn' fill onClick={() => this.onBack()}>
							Back
						</Button>
						<Button
							bsStyle='info'
							className='tertiaryButton'
							fill
							onClick={() => this.onExitCreateAppProcess()}>
							{bypass_app_type ? 'Exit' : 'My Account'}
						</Button>
						<Button
							form='form'
							bsStyle='info'
							className='save-btn'
							fill
							type='submit'
							onClick={() => this.onSubmit()}
							disabled={selection_missing}>
							Save and Continue
						</Button>
					</>
				}
			/>
		);
	};
}

const mapStateToProps = state => ({
		app: state.user.temp_app_rec.json_obj,
		campuses: state.global.campuses,
		cdrMajors: state.global.cdrMajors.filter(maj => maj.field_cmc_program_type !== 'Minor'), // minors should appear on Find a Program only,
		specials: state.global.specials,
		career_clusters: state.global.career_clusters
			.sort((a, b) => {
				if (a.title < b.title) return -1;
				if (a.title > b.title) return 1;
				return 0;
			})
			.map(cc => cc.title),
		bypass_term: state.user.obj_bypass.field_specific_term,
		bypass_app_type: state.user.obj_bypass.field_application_type,
		terms: state.global.terms,
		creating_additional_app: state.user.creating_additional_app,
		prev_page: state.global.prev_page
	}),
	mapDispatchToProps = dispatch => ({
		updateApp: obj => dispatch(UserActions.updateApp(obj)),
		setScreensCompleted: arg => dispatch(UserActions.setScreensCompleted(arg)),
		removeBypassObject: () => dispatch(UserActions.applyBypassObject())
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectMajor));
