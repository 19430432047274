import React, { Component } from 'react';
import { connect } from 'react-redux';

import { scrollTo } from 'components/modules/_misc';
import _svg from 'components/modules/svg';
import withRouter from 'components/Wrappers/withRouter';
import { UserActions } from 'reducers/user';

import Home from 'views/Public/shared-content/Home';

class Index extends Component {
	componentDidMount() {
		let { location, logout } = this.props,
			{ logging_out } = location.state || {};

		if (logging_out) logout();

		scrollTo();
	}

	render() {
		let { navigate } = this.props,
			{ REACT_APP_ENV } = process.env;

		return (
			<Home
				mainContent={
					<>
						<h2>
							Universities of Wisconsin Admission Pathway Portal
							{REACT_APP_ENV !== 'production' && (
								<>
									<br />({`${REACT_APP_ENV === 'practice' ? 'Practice' : 'Development'} Application`})
								</>
							)}
						</h2>

						<h1>
							all paths <span>start here</span>
						</h1>

						<div>
							<input
								type='button'
								onClick={() => navigate('/create-account')}
								className='btn-general primary'
								value='Create an account'
							/>
							<input
								type='button'
								onClick={() => navigate('/sign-in')}
								className='btn-general'
								value='sign in'
							/>
						</div>

						<p>
							Explore the{' '}
							<a className='pfa-home-link' onClick={() => navigate('/how-to-apply')}>
								paths for admission
							</a>
							.
						</p>
					</>
				}
				faqs={[
					{
						header: 'Issues logging into your account?',
						item: (
							<p>
								For issues or questions related to your account, call 800-442-6459 or email{' '}
								<a href='mailto:apply@wisconsin.edu'>apply@wisconsin.edu</a>.
							</p>
						)
					},
					{
						header: 'How can I get help with my Universities of Wisconsin Application?',
						item: (
							<p>
								For assistance with the Universities of Wisconsin Application, schedule a{' '}
								<a href='https://calendly.com/uwhelp/session' target='__blank'>
									virtual one-on-one session
								</a>
								.
							</p>
						)
					},
					{
						header: 'Is there an application fee for undergraduate applications?',
						item: (
							<>
								<p>
									Beginning August 1, 2024 for freshman and transfer applicants for the Fall 2025
									term, the following UW universities charge an application fee for every application
									submitted: UW-Eau Claire ($25), UW-La Crosse ($25), and UW-Madison ($70). For the
									other UW universities, there is no application fee.
								</p>
								<p>
									For domestic applicants, if you are concerned about paying application fees, please
									note your financial hardship on the Personal Information section of the application.
								</p>
							</>
						)
					},
					{
						header: 'How can I make changes to my submitted application?',
						item: (
							<>
								<p>
									If you have already submitted your application, please contact the admissions office
									directly to update your information.
								</p>
								<ul>
									<li>
										<a
											href={
												'https://uwhelp.wisconsin.edu/resources/campus-contacts/?type=undergraduate-admissions'
											}>
											Undergraduate admissions
										</a>
									</li>
									<li>
										<a
											href={
												'https://uwhelp.wisconsin.edu/resources/campus-contacts/?type=graduate-admissions'
											}>
											Graduate admissions
										</a>
									</li>
								</ul>
							</>
						)
					},
					{
						header: 'What is the status of my application?',
						item: (
							<>
								<p>
									Please contact the campus's admissions office directly with your full name, date of
									birth, and application ID. The admissions office will be able to share the status of
									your application.
								</p>
								<ul>
									<li>
										<a
											href='https://uwhelp.wisconsin.edu/resources/campus-contacts/?type=undergraduate-admissions'
											target='_blank'
											rel='noopener noreferrer'>
											Undergraduate admissions
										</a>
									</li>
									<li>
										<a
											href='https://uwhelp.wisconsin.edu/resources/campus-contacts/?type=graduate-admissions'
											target='_blank'
											rel='noopener noreferrer'>
											Graduate admissions
										</a>
									</li>
								</ul>
							</>
						)
					},
					{
						header: 'What classes are available?',
						item: (
							<>
								<p>
									Check out each{' '}
									<a
										href='https://uwhelp.wisconsin.edu/post-admission/uw-campus-timetables/'
										target='_blank'
										rel='noopener noreferrer'>
										campus's schedule of classes
									</a>{' '}
									or undergraduate catalog to find the perfect class for you.
								</p>
								<p>
									To view how credits may transfer to a UW, use the{' '}
									<a
										href='https://www.transferology.com/state/wisconsin.htm'
										target='_blank'
										rel='noopener noreferrer'>
										Transferology
									</a>{' '}
									tool or contact your Academic Advisor.
								</p>
							</>
						)
					}
				]}
			/>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	logout: () => dispatch(UserActions.logout())
});

export default withRouter(connect(null, mapDispatchToProps)(Index));
