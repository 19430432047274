import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserActions } from 'reducers/user';

import { scrollTo } from 'components/modules/_misc';
import withRouter from 'components/Wrappers/withRouter';
import Home from 'views/Public/shared-content/Home';

class DirectAdmit extends Component {
	constructor(props) {
		super(props);

		let d = new Date(),
			timezoneOffset = d.getTimezoneOffset();

		d = new Date(d.getTime() + (timezoneOffset - 300) * 60000);

		let month = d.getMonth() + 1;

		if (month >= 5 && month < 8) this.upcoming_year = d.getFullYear() + 1;
	}

	componentDidMount() {
		scrollTo();
	}

	render() {
		let { navigate, setDirectAdmit } = this.props,
			{ REACT_APP_ENV } = process.env;

		return (
			<Home
				directAdmit={true}
				mainContent={
					<>
						<h1>
							Direct Admit <span>Wisconsin</span>
						</h1>

						{REACT_APP_ENV !== 'production' && (
							<h2>{`${REACT_APP_ENV === 'practice' ? 'Practice' : 'Development'} Application`}</h2>
						)}

						{this.upcoming_year && REACT_APP_ENV === 'production' ? (
							<p className='direct-admit-upcoming-note'>
								Direct Admit forms for students graduating high school in the Spring of{' '}
								{this.upcoming_year} will be available August 1, {this.upcoming_year - 1}.
							</p>
						) : (
							<div>
								<input
									type='button'
									onClick={() => {
										setDirectAdmit();
										navigate('/create-account');
									}}
									className='btn-general primary'
									value='create account'
								/>

								<input
									type='button'
									onClick={() => {
										setDirectAdmit();
										navigate('/sign-in');
									}}
									className='btn-general'
									value='sign in'
								/>
							</div>
						)}
					</>
				}
				faqs={[
					{
						header: 'Issues logging into your account?',
						item: (
							<p>
								For issues or questions related to your account, call 800-442-6459 or email{' '}
								<a href='mailto:apply@wisconsin.edu'>apply@wisconsin.edu</a>.
							</p>
						)
					},
					{
						header: 'What is Direct Admit Wisconsin?',
						item: (
							<p>
								Direct Admit Wisconsin is an initiative by the Universities of Wisconsin to proactively
								offer admission to qualified Wisconsin high school students. For more information, visit{' '}
								<a href='https://www.wisconsin.edu/direct-admissions/' target='__blank'>
									the Direct Admit Wisconsin website
								</a>
								.
							</p>
						)
					},
					{
						header: 'How can I get help with my Direct Admit Form(s)?',
						item: (
							<p>
								For assistance with the Direct Admit Form,{' '}
								<a href='https://calendly.com/uwhelp/' target='__blank'>
									schedule a virtual one-on-one session
								</a>
								.
							</p>
						)
					},
					{
						header: 'If I already have a Universities of Wisconsin Admission Pathway Portal account, how do I view my Direct Admit admissions offers?',
						item: (
							<p>
								Log into your existing account with your email. Once logged in, you will see a “Direct
								Admit” tab. Click on the button “Link Your Direct Admit Record.” Follow the instructions
								to access your Direct Admit admissions offers.
							</p>
						)
					},
					{
						header: 'How do I know which UWs I have received Direct Admit admissions offers from?',
						item: (
							<p>
								When logged into your Universities of Wisconsin Admission Pathway Portal account, you
								can find a list of the UWs that have offered you Direct Admissions.
							</p>
						)
					},
					{
						header: 'I am interested in a UW university that is not on my Direct Admit admission offer list. What should I do?',
						item: (
							<p>
								You can apply for admission to any UW not on your Direct Admit offer letter by
								submitting a traditional application at apply.wisconsin.edu.
							</p>
						)
					},
					{
						header: 'Is the offer of admission to the UW university or to a specific major/academic program?',
						item: (
							<p>
								The Direct Admit admission offer is to the UW university. Some UW universities may have
								a secondary admission process in which students need to apply to be admitted into a
								specific major/academic program. Contact the UW university or academic program for more
								information.
							</p>
						)
					},
					{
						header: 'What happens after I submit a Direct Admit form?',
						item: (
							<p>
								After you submit a Direct Admit form, the UW university you are interested in will reach
								out to you with more information. Be sure to check your email for updates.
							</p>
						)
					},
					{
						header: 'Is Direct Admit Wisconsin the same thing as the Wisconsin Guarantee? ',
						item: (
							<p>
								Direct Admit Wisconsin and the Wisconsin Guarantee are different programs. For more
								information about the Wisconsin Guarantee, visit{' '}
								<a href='https://www.wisconsin.edu/wisconsin-guarantee/' target='__blank'>
									the Wisconsin Guarantee website
								</a>
								.
							</p>
						)
					}
				]}
			/>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setDirectAdmit: () => dispatch(UserActions.setDirectAdmit({ need_to_link: true }))
});

export default withRouter(connect(null, mapDispatchToProps)(DirectAdmit));
