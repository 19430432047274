import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import ShoppingCart from 'components/ShoppingCart';
import { toggleArray, cloneDeep } from 'components/modules/_misc';
import { create_proactive } from 'components/modules/ada';
import { has_fee } from 'components/modules/app';
import { date_reached } from 'components/modules/date';
import { nextAppPage } from 'components/modules/nav';
import { FIND_TERM, is_app_open, FIND_FILTERED_TERMS } from 'components/modules/term';

class ApplicationPayment extends Component {
	constructor(props) {
		super(props);

		this.later = !!props.location.state?.later || false;

		let { terms, apps_in_cart, apps } = props,
			apps_to_show = apps_in_cart.split('|').filter(_app_id => {
				const _app = apps[_app_id];
				return !!_app && this.isAppPayable(_app.json_obj) && is_app_open(_app.json_obj, terms, true);
			}),
			app_ids_to_pay = apps_to_show.filter(_app_id => {
				const _app = apps[_app_id];
				return has_fee(_app) && _app.paid === '0';
			}),
			amount = 0;

		app_ids_to_pay.forEach(_app_id => {
			amount += parseInt(apps[_app_id].application_fee);
		});

		this.state = {
			apps_to_show: apps_to_show,
			app_ids_to_pay: app_ids_to_pay,
			amount: amount
		};
	}

	componentDidMount() {
		this._mounted = true;

		setTimeout(() => {
			if (this._mounted) create_proactive('Have questions about paying your app fee?');
		}, 2000);
	}

	componentDidUpdate(prevProps, prevState) {
		let { apps } = this.props,
			{ app_ids_to_pay } = this.state,
			amount = 0;

		if (app_ids_to_pay.length !== prevState.app_ids_to_pay.length) {
			app_ids_to_pay.forEach(_app_id => {
				amount += parseInt(apps[_app_id].application_fee);
			});

			this.setState({ amount: amount });
		}
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	isAppPayable = _app => {
		const { terms } = this.props,
			term = FIND_TERM.by_app(_app, terms),
			chosen_semester_id = term?.id,
			international = _app.application_modifier.split('_')[1] === 'I';

		if (!chosen_semester_id || !_app.application_modifier || !!_app.term_bypassed) return true;

		let updated_term = FIND_FILTERED_TERMS.by_app(_app, terms).find(x => x.id === chosen_semester_id);

		if (updated_term) {
			let pay_date = '';

			if (updated_term.field_pay) {
				//Exposed already?  Specials and grads
				pay_date = updated_term.field_pay;
			} else {
				//Campus and undergrad default

				let app_type_name = '';

				if (_app.application_modifier.includes('FM_')) {
					app_type_name = 'freshman';
				} else if (_app.application_modifier.includes('TR_')) {
					app_type_name = 'transfer';
				} else if (_app.application_modifier.includes('RE_') || _app.application_modifier.includes('RET_')) {
					app_type_name = 're_entry';
				}

				let termObjectToUse = [];

				if (updated_term[`field_${app_type_name}`]) {
					termObjectToUse = updated_term[`field_${app_type_name}`];
				} else if (updated_term[`field_${app_type_name}_campus`]) {
					termObjectToUse = updated_term[`field_${app_type_name}_campus`];
				}

				termObjectToUse.forEach(obj => {
					if (
						(international && obj.field_type === 'International') ||
						(!international && obj.field_type === 'Domestic')
					) {
						pay_date = obj.field_pay;
					}
				});
			}

			if (pay_date && date_reached(pay_date)) return false;
		}

		return true;
	};

	cashnetUrl = () => {
		let { REACT_APP_ENV } = process.env,
			CASHNET_URL = 'https://commerce.cashnet.com/',
			{ apps } = this.props,
			{ app_ids_to_pay } = this.state;

		app_ids_to_pay.forEach((_app_id, i) => {
			let _app = apps[_app_id],
				{ field_cashnet_api_key, field_cashnet_item_code } = _app.json_obj.initial_information.chosen_campus;

			if (REACT_APP_ENV !== 'production') {
				field_cashnet_api_key = 'ADMUWSYST';
				field_cashnet_item_code = field_cashnet_item_code
					.substr(0, field_cashnet_item_code.length - 1)
					.replace('ADMUWSYS', 'ADMUWSYST');
			}

			CASHNET_URL += i ? '&' : field_cashnet_api_key + '?';
			CASHNET_URL += `itemcode${i + 1}=${field_cashnet_item_code}`;
			CASHNET_URL += `&amount${i + 1}=${_app.application_fee}`;
			CASHNET_URL += `&ref1type${i + 1}=APP_ID`;
			CASHNET_URL += `&ref1val${i + 1}=${_app.application_id}`;
		});

		return CASHNET_URL;
	};

	onChangePay = _app_ids => {
		let app_ids_to_pay = cloneDeep(this.state.app_ids_to_pay);

		_app_ids.forEach(_app_id => {
			app_ids_to_pay = toggleArray(app_ids_to_pay, _app_id);
		});

		this.setState({ app_ids_to_pay: app_ids_to_pay });
	};

	renderPage = txtPay => {
		let { apps_to_show, app_ids_to_pay, amount } = this.state;

		if (!apps_to_show.length) return null;

		return (
			<div className='application-payment-container'>
				<div className='application-payment-margin'>
					<h3>Submitted Applications</h3>
					<ShoppingCart
						role='PAY'
						app_ids_to_pay={app_ids_to_pay}
						onChangePay={this.onChangePay}
						later={this.later}
					/>
				</div>

				{!!amount && (
					<div className='application-payment-margin'>
						<p>
							<b>Doing Business As:</b> The charge will appear on your credit card statement as UW SYS
							ADMISSIONS APP FEE.
						</p>

						<p>
							<b>Refund Policy:</b> The application fee is non-refundable. Exceptions may be made if you
							pay the fee when not required or the application is received after the application deadline.
							If you have questions or believe you are due a refund, please contact the admissions office
							for the campus you applied to.
						</p>

						<p>
							<b>Privacy Statement:</b> The University of Wisconsin System does not sell, rent, trade or
							distribute any personally identifiable information obtained from applicants to a third
							party, except in cooperation with government agencies or as required by law. Information
							collected by the University of Wisconsin System is used for the administrative and
							operational purposes of our services.
						</p>

						<p>If you have questions about an application fee payment contact:</p>
						<p>UW HELP</p>

						<p>800-442-6459</p>

						<p>
							<a href='mailto:apply@wisconsin.edu'>apply@wisconsin.edu</a>
						</p>

						<p className='uwRedText'>
							If you select {txtPay} you will be redirected to our payment provider to make your
							application fee payment.
						</p>
					</div>
				)}
			</div>
		);
	};

	render() {
		let { app, navigate, location } = this.props,
			{ apps_to_show, app_ids_to_pay, amount } = this.state;

		if (!apps_to_show.length) return null;

		const txtPay = this.later ? `PAY APP FEE${app_ids_to_pay.length > 1 ? 'S' : ''}` : 'PAY NOW',
			fee_to_pay = app.submit.waive_fee !== 'Yes' && !!amount,
			txtPayLater = fee_to_pay ? 'Pay Later' : 'Continue';

		return (
			<Card
				title='PAYMENT'
				content={
					<form id='form'>
						{this.renderPage(txtPay)}
						<div className='clearfix' />
					</form>
				}
				buttons={
					<>
						{this.later ? (
							<Button
								bsStyle='info'
								className={amount ? 'tertiaryButton' : 'save-btn'}
								onClick={() => navigate('/my-account')}>
								{amount ? 'Back' : 'OK'}
							</Button>
						) : (
							<Button
								bsStyle='info'
								className='save-btn'
								onClick={() => navigate(nextAppPage(app, location.pathname))}>
								{txtPayLater}
							</Button>
						)}
						{fee_to_pay && (
							<a href={this.cashnetUrl()} className='action'>
								{txtPay} (${amount})
							</a>
						)}
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => {
	const { apps, app_id, apps_in_cart } = state.user;

	return {
		terms: state.global.terms,
		apps: apps,
		app: apps[app_id].json_obj,
		apps_in_cart: apps_in_cart
	};
};

export default withRouter(connect(mapStateToProps)(ApplicationPayment));
