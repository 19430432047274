import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import Button from 'components/CustomButton';
import withRouter from 'components/Wrappers/withRouter';

const footer = require('assets/homepageImages/UWs-logo/std.png'),
	footer500 = require('assets/homepageImages/UWs-logo/std-500.png'),
	footer800 = require('assets/homepageImages/UWs-logo/std-800.png'),
	footer1080 = require('assets/homepageImages/UWs-logo/std-1080.png');

class Footer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modal: ''
		};

		this.is_direct_admit = props.location?.pathname === '/direct-admit';
	}

	renderModals = () => {
		const { modal } = this.state;

		return (
			<>
				<Modal show={modal === 'POLICIES'}>
					<Modal.Header>
						<Modal.Title className='h2'>UW System Policies</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<h4 className='bold'>Equal Opportunity:</h4>
						<p>
							The UW System is committed to equal opportunity for all persons regardless of race, color,
							sex, creed, age, ancestry, religion, national origin, disability, sexual orientation,
							political affiliation, pregnancy, parental status, or marital status in its educational
							programs, activities and employment policies. This statement is published in accordance with
							Section 504 of the Rehabilitation Act of 1973 (as amended), and the Americans with
							Disabilities Act (ADA).
						</p>
						<p>
							Inquiries concerning compliance with the requirements of Title IX referring to equal
							opportunity regardless of sex may be directed to the UW System Office of Equal Opportunity
							Compliance, 1570 Van Hise Hall, 1220 Linden Drive, Madison, Wisconsin 53706 (608-262-5504).
							Inquiries pertaining to nondiscrimination on the basis of disability may be addressed to the
							Office of the Chancellor at the institution to which you are applying.
						</p>

						<h4 className='bold'>Personal Information:</h4>
						<p>
							Personal information provided in your in-progress application(s) may be used by the UW
							System and its campuses to contact you about submitting your application. Upon submission of
							an application, the personal data collected in the Universities of Wisconsin Admissions
							Portal will be shared with the UW System campus(es) that you are applying to. This data will
							be used to set up your record at the campus to be considered for admission. Submitted
							applications are property of the UW System.
						</p>

						<h4 className='bold'>
							Use and Disclosure of Social Security Number (SSN) or Other Taxpayer Identification Number
							(TIN):
						</h4>
						<p>
							Federal law allows the University to request and use your Social Security or other Taxpayer
							Identification Number (SSN or TIN) for routine record keeping, institutional statistics and
							research, and compliance with Federal and State reporting requirements. Although providing
							the SSN or TIN is not required for admission to the University, it is required for financial
							aid and ensures that you will be able to claim the new educational tax benefits, if
							eligible, on your federal tax return. The University may use the SSN or TIN for billing and
							collection efforts, financial aid, internal verification and administrative purposes.
						</p>
						<p>
							The SSN or TIN is protected under the Federal Privacy Act of 1974 (5 U.S.C. 552a) and the
							Family Educational Rights and Privacy Act (FERPA) (20 U.S.C. 1232g) and will only be
							disclosed as provided above or with the student&apos;s consent or as otherwise required or
							permitted by law.
						</p>

						<h4 className='bold'>Use and Disclosure of Racial/Ethnic Information:</h4>
						<p>
							The U.S. Department of Education (DOE) requires the University to report the number of
							students in various racial/ethnic categories. Your cooperation in furnishing accurate
							information will be appreciated.
						</p>
						<p>
							Racial/ethnic heritage information will be treated as confidential and will not appear on
							academic records, grade reports, class lists or transcripts. Individual student
							racial/ethnic information will be released only with the student&apos;s consent or as
							otherwise permitted by law.
						</p>

						<h4 className='bold'>Services for Veterans:</h4>
						<p>
							For the purposes of this application only, a veteran is a former or current member of the
							United States Military. Veterans no longer serving need to have been discharged in good
							standing. Eligibility for benefits or services will vary based upon the individual&apos;s
							specific level of service. Please note that other agencies or applications may use a
							different definition to establish veteran status.
						</p>

						<p>
							UW System institutions are approved to offer veterans&apos; educational benefits under
							programs provided by the U.S Department of Veterans Affairs and the Wisconsin Department of
							Veterans Affairs. There are also programs and benefits that provide services to spouses and
							children of those who have or are currently serving in the U.S Military.
						</p>

						<p>
							For information on veterans&apos; educational benefits, contact the U.S. Department of
							Veterans Affairs Education Service at 800-827-1000 or visit their website at{' '}
							<a
								className='fixATag'
								target='_blank'
								href='https://www.gibill.va.gov'
								rel='noopener noreferrer'>
								www.gibill.va.gov
							</a>
							. Information on Wisconsin state benefits is available from the Wisconsin Department of
							Veterans Affairs: call 800-947-8387 or visit their website at{' '}
							<a
								className='fixATag'
								target='_blank'
								href='https://dva.state.wi.us/Ben-education.asp'
								rel='noopener noreferrer'>
								dva.state.wi.us/Ben-education.asp
							</a>
							. The names and phone numbers of veterans affairs coordinators for each UW System
							institution can be found in the UW HELP website at{' '}
							<a
								className='fixATag'
								target='_blank'
								href='https://uwhelp.wisconsin.edu/campus-contacts'
								rel='noopener noreferrer'>
								uwhelp.wisconsin.edu/campus-contacts
							</a>
							.
						</p>
					</Modal.Body>
					<Modal.Footer>
						<Button bsStyle='info' className='save-btn' fill onClick={() => this.setState({ modal: '' })}>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
				<Modal show={modal === 'ACCESSIBILITY'}>
					<Modal.Header>
						<Modal.Title className='h2'>ACCESSIBILITY</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>
							Please reach out to the UW HELP office if you find that the Universities of Wisconsin
							Admission Pathway Portal is not meeting your needs for accessibility purposes. We will
							document your request and provide you with updates on a solution.
						</p>
						<p>
							We take your requests and suggestions seriously. We want to make the application accessible
							for all.
						</p>
						<p>
							Contact UW HELP by phone or email. <br />
							(Monday through Friday, 8:00 am to 4:30 pm, Central time)
						</p>
						<p>
							Phone: <a href='tel:+1-800-442-6459'>1-800-442-6459</a> <br />
							E-mail:{' '}
							<a className='fixATag' href='mailto:apply@wisconsin.edu'>
								apply@wisconsin.edu
							</a>
						</p>
					</Modal.Body>
					<Modal.Footer>
						<Button bsStyle='info' className='save-btn' fill onClick={() => this.setState({ modal: '' })}>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
	};

	render() {
		const { paper_application_url } = this.props,
			{ REACT_APP_ENV } = process.env;

		return (
			<>
				{this.renderModals()}

				<footer id='footer' className='footer'>
					<div className='container w-container'>
						<div className='footer-flex-container'>
							<a className='footer-logo-link w-inline-block'>
								<img
									src={footer}
									srcSet={`${footer500} 500w, ${footer800} 800w, ${footer1080} 1080w, ${footer} 1647w`}
									sizes='200px'
									alt=''
									className='footer-image'
								/>
							</a>
							<div className='div-block-5'>
								<h2 className='footer-nav' onClick={() => this.setState({ modal: 'POLICIES' })}>
									UWS POLICIES
								</h2>
							</div>
							<div>
								<h2 className='footer-nav' onClick={() => this.setState({ modal: 'ACCESSIBILITY' })}>
									ACCESSIBILITY
								</h2>
							</div>
							{!this.is_direct_admit && (
								<div>
									<h2
										className='footer-nav'
										onClick={() => window.open(paper_application_url, '_blank')}>
										PAPER APPLICATION
									</h2>
								</div>
							)}

							<div>
								<h2 className='footer-nav'></h2>
							</div>
						</div>

						<div className='text-block-2'>
							Copyright © 2024 by the Board of Regents of the Universities of Wisconsin.
						</div>

						{REACT_APP_ENV !== 'production' && (
							<div className='text-block-2 uwRedText bold'>
								This {REACT_APP_ENV === 'practice' ? 'practice' : 'development'} site is updated
								periodically and doesn&apos;t always align with the live application website. The last
								update was 3/9/2021.
							</div>
						)}
					</div>

					<script
						src='https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.4.1.min.220afd743d.js?site=5ea1ccd99c38e78f25db182e'
						type='text/javascript'
						integrity='sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo='
						crossOrigin='anonymous'></script>
					<script src='assets/webflowJS/webflow.js' type='text/javascript'></script>
				</footer>
			</>
		);
	}
}

const mapStateToProps = state => ({
	paper_application_url: state.global.paper_application_url
});

export default withRouter(connect(mapStateToProps)(Footer));
